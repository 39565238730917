<template>
  <footer>
    <div class="footer-left">
      <h4 class="uppercase-text">Kontakt</h4>
      <p>
        <span
          ><img
            src="@/assets/mail_white_24dp.svg"
            alt="kontakt email"
            loading="lazy"
        /></span>
        hello@spika.hr
      </p>
      <p>
        <span
          ><img
            src="@/assets/phone_white_24dp.svg"
            alt="kontakt broj"
            loading="lazy"
        /></span>
        098 187 9400
      </p>
      <div class="copyright-mobile">
        <p>&copy; Copyright Škola stranih jezika Spika 2021</p>
      </div>
    </div>

    <div class="footer-center">
      <div class="top-part">
        <h3 class="uppercase-text follow-text">
          Zaprati nas za besplatne vježbe i lekcije!
        </h3>
      </div>
      <div class="bottom-part footer-images">
        <div class="socials">
          <a
            href="https://www.facebook.com/SPIKA-%C5%A1kola-stranih-jezika-101294848868045"
            target="_blank"
          >
            <img
              src="@/assets/facebook.svg"
              alt="Facebook škola"
              loading="lazy"
            />
          </a>
          <a href="https://www.instagram.com/spika.hr/" target="_blank">
            <img src="@/assets/instagram.svg" alt="Instagram" loading="lazy" />
          </a>
          <a href="https://www.linkedin.com/company/spikahr" target="_blank">
            <img
              src="@/assets/linkedin.svg"
              alt="Linkdin learning"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      <div class="copyright">
        <p>&copy; Copyright Škola stranih jezika Spika 2025</p>
      </div>
    </div>

    <div class="footer-right">
      <img
        src="@/assets/cup.webp"
        class="mug-image"
        alt="Učenje od kuće"
        loading="lazy"
      />
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
